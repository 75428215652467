import wretch from 'wretch'

const BASE_URL = process.env.VUE_APP_API_BASE || 'https://myphonerobot.com'

/**
 * @query to=&from=&real=
 * to - number to call
 * from - spoof number
 * real = user number
 * token = reCaptcha response
 * @response { "result": [ { "message" : "Free spoof calls are supported only to/from phone numbers within USA. If you are trying to dial a phone number within USA, please add +1 in front of the phone number."  , "info" : "" } ]}
 * @type {string}
 */
const FREE_CALL = '/script25/rest/freespoofcall.php'

/**
 * @query user=&pass=&email=
 * user - username
 * pass - password
 * email - email
 * token = reCaptcha response
 * @response { "result": [ { "statusLine" : "<font color=\"333\">Some message</font>"  , "message" : "user registered" } ]}
 * @type {string}
 */
const REGISTER = '/script25/join.php'

/**
 * @query user=&pass=
 * user - username
 * pass - password
 * @response { "result": [ { "message" : "login_ok"  , "statusLine" : "<font color='#333333' face='Georgia'><i>Secret Bonus: We'll give you 150 free credits if you purchase credits NOW.</i></font>"  , "session_id" : "18pk9e33qfniv8j2p4ou07q3j1" } ]}
 * @type {string}
 */
const LOGIN = '/script25/login.php'

/**
 * @method POST
 * @param email
 * @response { "result": [ { "message" : "This email has not been verified and therefore it cannot be used for account recovery." } ]}
 * @type {string}
 */
const FORGOT = '/script25/resetp.php'

/**
 * @query body&from&user
 * body - message
 * from - user's email address
 * user - user's username
 * @response { "result": [ { "message" : "Your feedback has been accepted. Thank you!" } ]}
 * @type {string}
 */
const FEEDBACK = '/script25/feedback.php'

/**
 * @body
 * user - username
 * pass - password
 * to - target phone
 * @response { "result": [ { "message" : "This number is already blocked." } ]}
 * @type {string}
 */
const BLOCK_NUMBER = '/script25/block.php'

/**
 * @response { "result": [ { "username" : "guest user"  , "credits" : "0"  , "adminMessage" : "<a href='https://play.google.com/store/apps/details?id=com.tiggzi.project54500'>Update this app!</a>"  , "url" : "" } ]}
 * @type {string}
 */
const USER_INFO = '/script25/userInfo.php'

/**
 * @body
 * to - number to call
 * from - spoof number
 * real - user number
 * voice - [no_voice, male, female]
 * audio - [party]
 * record -
 * pin - 448844???
 * user - email
 * pass - password
 * contactName - ???
 * @type {Wretcher}
 */
const VOICE_CHANGE_CALL = '/script25/rest/vcall.php'

/**
 * @body
 * user - email
 * voucher - voucher code
 * @response { "message" : "The entered voucher is either not valid or already used."  , "error" : true, "success" : true }
 * @type {string}
 */
const VOUCHER = '/script25/promocode.php'

/**
 * @query deploy=web&user
 * deploy - interface type - web
 * user - user's email
 * @response {  "items": [ { "method" : "Credit Card via PayPal" ,"description" : "150 free credits on first purchase","target" : "","spec" : "width=1000, height=800"  , "integration" : "url", "value" : "https://myphonerobot.com/script25/paypal/token.php?landing=Billing&pin=816633&user=admin1@admin.admin" }, { "method" : "PayPal" ,"description" : "150 free credits on first purchase","target" : "","spec" : "width=1000, height=800"  , "integration" : "url", "value" : "https://myphonerobot.com/script25/paypal/token.php?landing=Login&pin=816633&user=admin1@admin.admin" }]}
 * @type {string}
 */
const PAYMENT_METHODS = '/script25/payoptions.php'

/**
 * @body
 * oldPassword
 * newPassword
 * user - email
 * @response { "result": [ { "message" : "You need to verify your email address before changing your password." } ]}
 * @type {string}
 */
const CHANGE_PASSWORD = '/script25/changep.php'

/**
 * @query user&code&mode
 * user - email
 * code - activation code
 * mode - web or ...
 * @response
 * @type {string}
 */
const VERIFY_EMAIL = '/script25/activate.php'

/**
 * @body
 * user - email
 * pass - password
 * @response { "result": [ { "message" : "Veification code sent." } ]}
 * @type {string}
 */
const RESEND_VERIFICATION_EMAIL = '/script25/resendcode.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * newEmail - new email
 * @response { "result": [ { "message" : "This email is already set in your account. Setting it again will have no effect." } ]}
 * @type {string}
 */
const CHANGE_EMAIL = '/script25/emailchange.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * credits - credits
 * @response { "message" : "Error message."  , "error" : true, "success" : true, "price": 0 }
 * @type {string}
 */
const BITCOIN_BITPRICE = '/script25/bitcoin/bitprice.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * credits - credits
 * @response { "message" : "Error message."  , "error" : true, "success" : true, "price": 0, "address": "" }
 * @type {string}
 */
const BITCOIN_ADDRESS = '/script25/bitcoin/create.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * credits - credits
 * processor - processor
 * firstName - firstName
 * lastName - lastName
 * city - city
 * address - address
 * zip - zip
 * state - state
 * phone - phone
 * token = reCaptcha response
 * @response { "message" : "Error message."  , "error" : true, "success" : true, "orderId": 0, "paymentLink": ""}
 * @type {string}
 */
const CREDIT_CARD_ORDER = '/script25/orders/cc.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * @response { "message" : "Error message."  , "error" : true, "success" : true, "price_points": [{"credits": 220,"bonus_credits": 66,"cost": 7.95}]}
 * @type {string}
 */
const PRICE_POINTS = '/script25/pricepoints.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * @response { "message" : "Error message."  , "error" : true, "success" : true, "recordings": [ { "logdate": "", "target": "", "url": "", "log_id": 1 } ] }
 * @type {string}
 */
const RECORDING_LOGS = '/script25/recordings.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * credits - credits
 * processor - ['coingate','switchere']
 * @response { "message" : "Error message."  , "error" : true, "success" : true, "orderId": 0}
 * @type {string}
 */
const CREATE_ORDER = '/script25/orders/create.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * credits - credits
 * @response { "message" : "Error message."  , "error" : true, "success" : true, "paymentUrl": 0}
 * @type {string}
 */
const COINGATE_CREATE_PAYMENT = '/script25/coingate/create.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * orderId - orderId
 * cardnumber - cardnumber
 * expirationdate - expirationdate
 * securitycode - securitycode
 * token = reCaptcha response
 * @response { "message" : "Error message."  , "error" : true, "success" : true}
 * @type {string}
 */
const INOVIO_PAY = '/script25/inovio/pay.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * @response { "message" : "Error message."  , "error" : true, "success" : true, "code": "XT6NUJG6",
    "url": "", "message": "", "count": 0, "description": ""}
 }
 * @type {string}
 */
const SHARE = '/script25/share.php'

/**
 * @method POST
 * @body
 * token - JWT token from google sign in
 * @response {"success":true, "data":{"username":"user@email.com","password":"userpassword"}}
 * @type {string}
 */
const GOOGLE_TOKEN_SIGN_IN = '/script25/google/mpr/tokensignin.php'

/**
 * @method POST
 * @body
 * code - authorization code from apple
 * @response {"success":true, "data":{"username":"user@email.com","password":"userpassword"}}
 * @type {string}
 */
const APPLE_TOKEN_SIGN_IN = '/script25/apple/mpr/tokensignin.php'

/**
 * @body
 * to - number to call
 * from - spoof number
 * real - user number
 * voice - [no_voice, male, female]
 * record - [0,1]
 * user - email
 * pass - password
 */
const CALL_REQUEST = '/script25/rest/callrequest.php'

/**
 * @body
 * to - number to call
 * from - spoof number
 * real - user number
 * voice - [no_voice, male, female]
 * record - [0,1]
 * user - email
 * pass - password
 */
const FREE_CALL_REQUEST = '/script25/rest/freecallrequest.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * @response { "message" : "Error message."  , "error" : true, "success" : true,
 * "data": [{"network":"Bitcoin","code":"BTC","label":"BTC","rate":"0.00003181"}]
 * @type {string}
 */
const SWITCHERE_CURRENCIES = '/script25/switchere/currencies.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * orderId - number
 * currency - ['BTC', 'LTC', 'ETH', 'USDT']
 * @response { "message" : "Error message."  , "error" : true, "success" : true, "data":
 * { "client_order_id": "","crypto_address": "", "crypto_delay": "", "payin_amount": "", "payin_currency": ""}
 * @type {string}
 */
const SWITCHERE_CREATE_ORDER = '/script25/switchere/create.php'

/**
 * @method POST
 * @body
 * user - email
 * pass - password
 * orderId - number
 * currency - ['BTC', 'LTC', 'ETH', 'USDT']
 * @response { "message" : "Error message."  , "error" : true, "success" : true, "data":
 * { "client_order_id": "","crypto_address": "", "crypto_delay": "", "payin_amount": "", "payin_currency": "", "status":""}
 * @type {string}
 */
const SWITCHERE_ORDER = '/script25/switchere/order.php'

/**
 * @body
 * number - spoof number
 * user - email
 * pass - password
 */
const CHARGE_SPOOF = '/script25/rest/chargespoof.php'

const api = wretch()
  .url(BASE_URL)

export const freeCall = async ({ from, to, real, token }) =>
  api.url(FREE_CALL)
    .query({ from, to, real, token })
    .post()
    .json(({ result: [{ info, message }] }) => ({ success: info === 'ok', message }))

export const register = async ({ email, password: pass, token, code }) =>
  api.url(REGISTER)
    .formUrl({ user: email, email, pass, app: 'myphonerobot', token, code })
    .post()
    .json(({ result: [{ statusLine, message }] }) => ({
      success: message === 'user_registered',
      message: statusLine || message
    }))

export const login = async ({ email: user, password: pass }) =>
  api.url(LOGIN)
    .formUrl({ user, pass })
    .post()
    .json(({ result: [{ statusLine, session_id: sessionId, message }] }) => ({
      success: message === 'login_ok',
      sessionId,
      message: statusLine || message
    }))

export const forgot = async ({ email }) =>
  api.url(FORGOT)
    .formUrl({ email })
    .post()
    .json(({ result: [{ message }] }) => ({
      message
    }))

export const feedback = async ({ email: from, message: body, user }) =>
  api.url(FEEDBACK)
    .formData({ body, from, user })
    .post()
    .json(({ result: [{ message }] }) => ({
      message
    }))

export const blockNumber = async ({ to }) =>
  api.url(BLOCK_NUMBER)
    .formUrl({ to })
    .post()
    .json(({ result: [{ message, success = false }] }) => ({
      message,
      success
    }))

export const getUserInfo = async ({ email: user, password: pass }) =>
  api.url(USER_INFO)
    .formUrl({ user, pass, version: '4.0' })
    .post()
    .json((
      {
        result: [{
          active,
          username: email,
          adminMessage: message,
          credits,
          order,
          phones,
          showSwitchere,
          showCreditCards
        }]
      }
    ) => ({
      active: active === '1' || active === true || active === 1,
      email,
      message,
      credits: parseInt(credits),
      order,
      phones,
      showSwitchere,
      showCreditCards
    }))

export const voiceChangerCall = async ({ from, to, real, email: user, password: pass, voice, recording }) =>
  api.url(VOICE_CHANGE_CALL)
    .formUrl({
      from,
      to,
      real,
      user,
      pass,
      voice: voice,
      recording: recording,
      pin: 448844
    })
    .post()
    .json(({ result: [{ info: id, message }] }) => ({ success: !!id, id, message }))

export const voucher = async ({ code, email: user }) =>
  api.url(VOUCHER)
    .formUrl({ code, user })
    .post()
    .json(({ success, message }) => ({ success, message }))

export const fetchPaymentMethods = async ({ email: user }) =>
  api.url(PAYMENT_METHODS)
    .query({ user, deploy: 'web' })
    .get()
    .json(({ items }) => items)

export const changePassword = async ({ email: user, oldPassword, newPassword }) =>
  api.url(CHANGE_PASSWORD)
    .formUrl({ user, oldPassword, newPassword })
    .post()
    .json((
      { result: [{ message, session_id: sessionId }] }
    ) => ({
      success: !!sessionId,
      message,
      sessionId
    }))

export const verifyEmail = async ({ email: user, code }) =>
  api.url(VERIFY_EMAIL)
    .query({ user, code })
    .get()
    .json(({ result: [{ info, message, success = !!info }] }) => ({
      success,
      info,
      message
    }))

export const resendVerificationEmail = async ({ email: user, password: pass }) =>
  api.url(RESEND_VERIFICATION_EMAIL)
    .formUrl({ user, pass })
    .post()
    .json(({ result: [{ message }] }) => ({
      success: message !== 'login_failed',
      message
    }))

export const changeEmail = async ({ email: user, password: pass, newEmail }) =>
  api.url(CHANGE_EMAIL)
    .formUrl({ user, pass, newEmail })
    .post()
    .json((
      { result: [{ message }] }
    ) => ({
      success: false,
      message
    }))

export const bitprice = async ({ user, pass, credits }) =>
  api.url(BITCOIN_BITPRICE)
    .formUrl({ user, pass, credits })
    .post()
    .json()

export const bitcoinAddress = async ({ user, pass, credits }) =>
  api.url(BITCOIN_ADDRESS)
    .formUrl({ user, pass, credits })
    .post()
    .json()

export const creditCardOrder = async ({
  user,
  pass,
  credits,
  processor,
  firstName,
  lastName,
  city,
  address,
  zip,
  state,
  phone,
  token
}) =>
  api.url(CREDIT_CARD_ORDER)
    .formUrl({ user, pass, credits, processor, firstName, lastName, city, address, zip, state, phone, token })
    .post()
    .json()

export const createOrder = async ({ user, pass, credits, processor }) =>
  api.url(CREATE_ORDER)
    .formUrl({ user, pass, credits, processor })
    .post()
    .json()

export const createCoingateOrder = async ({ user, pass, orderId }) =>
  api.url(COINGATE_CREATE_PAYMENT)
    .formUrl({ user, pass, orderId })
    .post()
    .json()

export const createInovioPayment = async ({ user, pass, orderId, cardnumber, expirationdate, securitycode, token }) =>
  api.url(INOVIO_PAY)
    .formUrl({ user, pass, orderId, cardnumber, expirationdate, securitycode, token })
    .post()
    .json()

export const getPricePoints = async ({ user, pass }) =>
  api.url(PRICE_POINTS)
    .formUrl({ user, pass })
    .post()
    .json()

export const getShare = async ({ user, pass }) =>
  api.url(SHARE)
    .formUrl({ user, pass })
    .post()
    .json()

export const getRecordingLogs = async ({ user, pass }) =>
  api.url(RECORDING_LOGS)
    .formUrl({ user, pass })
    .post()
    .json()

export const googleLogin = async ({ token }) =>
  api.url(GOOGLE_TOKEN_SIGN_IN)
    .formData({ token })
    .post()
    .json()

export const appleLogin = async ({ code }) => {
  console.log('send code to backend')
  return api.url(APPLE_TOKEN_SIGN_IN)
    .formData({ code })
    .post()
    .json()
}

export const callRequest = async ({ from, to, real, email: user, password: pass, voice, recording }) =>
  api.url(CALL_REQUEST)
    .formUrl({
      from,
      to,
      real,
      user,
      pass,
      voice: voice,
      recording: recording,
      pin: 448844
    })
    .post()
    .json()

export const callFreeRequest = async ({ from, to, real, email: user, password: pass, voice, recording, token }) =>
  api.url(FREE_CALL_REQUEST)
    .formUrl({
      from,
      to,
      real,
      user,
      pass,
      voice: voice,
      recording: recording,
      token
    })
    .post()
    .json()

export const getSwitchereCurrencies = async ({ user, pass }) =>
  api.url(SWITCHERE_CURRENCIES)
    .formUrl({ user, pass })
    .post()
    .json()

export const createSwitchereOrder = async ({ user, pass, orderId, currency }) =>
  api.url(SWITCHERE_CREATE_ORDER)
    .formUrl({ user, pass, orderId, currency })
    .post()
    .json()

export const getSwitchereOrder = async ({ user, pass, id }) =>
  api.url(SWITCHERE_ORDER)
    .formUrl({ user, pass, id })
    .post()
    .json()

export const chargeSpoof = async ({ user, pass, number }) =>
  api.url(CHARGE_SPOOF)
    .formUrl({ user, pass, number })
    .post()
    .json()
